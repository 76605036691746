main {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #F1CFCF;
}

.wrapper {
  width: 410px;
  height: 410px;
  transform-origin: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  animation: circle 10s ease-in-out infinite;
  transform-origin: center;
  background-color: #5ea9be;
}

p {
  font-size: 2rem;
  position: absolute;
  color: #fdf8e2;
}

.in {
  opacity: 1;
  visibility: visible;
  animation: text1 10s linear infinite;
}

.out {
  opacity: 0;
  visibility: hidden;
  animation: text2 10s linear infinite;
}

@keyframes text1 {
  0% {
    opacity: 1;
    visibility: visible;
  }
  45% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 0;
    visibility: hidden;
  }
  95% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes text2 {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  45% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  95% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes circle {
  0% {
    width: 300px;
    height: 300px;
  }
  50% {
    width: 400px;
    height: 400px;
  }
  100% {
    width: 300px;
    height: 300px;
  }
}
.in img,
.out img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
p.in,
p.out {
  text-align: center;
}
.playlist {
  position: fixed;
  bottom: 0;
  width: 100%;
}
h1 {
  text-align: center;
  font-size: 2rem;
  color: #5ea9be;
}
.loader {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #F1CFCF;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}